<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card title="Attributes and Values">
          <b-row>
            <b-col cols="12">
              <div>
                <!-- Row Loop -->
                <b-row v-for="(item, index) in product.attributes" :key="index">
                  <!-- Attrbuites -->
                  <b-col md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="attribute"
                      rules="required"
                    >
                      <b-form-group
                        label="Attribute"
                        label-for="attribute"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          :id="'attributes' + index"
                          disabled
                          v-model="attrSelect[index]"
                          @input="getValues(index, attrSelect[index].value)"
                          :state="attrSelect[index] === null ? false : true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="attr2Options"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="values"
                      rules="required"
                    >
                      <b-form-group
                        label="Values"
                        label-for="values"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          multiple
                          :id="'values' + index"
                          v-model="valuesSelect[index]"
                          :state="valuesSelect[index] === null ? false : true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="valuesOptions[index]"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Remove Button -->
                  <!-- <b-col lg="2" md="3" class="mb-50">
                                            <b-button v-if="index > 0" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" @click="taxonomyremoveItem(index)">
                                                <feather-icon icon="XIcon" class="mr-25" />
                                                <span>Remove</span>
                                            </b-button>
                                        </b-col> -->
                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <!-- add new button -->
            <!-- <b-col sm="2">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" block @click="taxonomyrepeateAgain">
                                    <feather-icon icon="PlusIcon" class="mr-25" />
                                    <span>Add New</span>
                                </b-button>
                            </b-col> -->
          </b-row>
          <b-button class="mt-2" @click="generate" block variant="success">
            Generate
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="12" v-if="varints.length > 0">
        <b-card title="Variants" ref="varintsHeight">
          <b-row>
            <b-col cols="12">
              <div>
                <!-- Row Loop -->
                <b-row v-for="(item, index) in varints" :key="index">
                  <b-col cols="12">
                    <validation-observer ref="element">
                      <b-row>
                        <b-col cols="12">
                          <app-collapse
                            id="faq-payment-qna"
                            accordion
                            type="margin"
                            class="mt-0 mb-0"
                          >
                            <b-row>
                              <b-col cols="10" class="">
                                <app-collapse-item
                                  :key="index"
                                  :title="index + 1 + ')' + ' ' + item.vname"
                                >
                                  <b-row>
                                    <!-- varint price -->
                                    <b-col md="4">
                                      <b-form-group
                                        label="Price"
                                        label-for="price"
                                      >
                                        <validation-provider
                                          #default="{ errors }"
                                          name="price"
                                          rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                                        >
                                          <b-form-input
                                            :id="'price' + index"
                                            v-model="item.voriginal_price"
                                            type="number"
                                            @input="changeOP(index)"
                                            :state="
                                              errors.length > 0 ? false : null
                                            "
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col>
                                    <!-- varint Amount -->
                                    <b-col md="4">
                                      <b-form-group
                                        label="Amount"
                                        label-for="amount"
                                      >
                                        <b-form-input
                                          min="1"
                                          :id="'amount' + index"
                                          type="number"
                                          @input="changeAmount(index)"
                                          v-model="item.offerBDel.amount"
                                          placeholder="enter amount"
                                        />
                                      </b-form-group>
                                    </b-col>
                                    <b-col md="4">
                                      <b-form-group
                                        label="Sale Price"
                                        label-for="sale price"
                                      >
                                        <validation-provider
                                          #default="{ errors }"
                                          name="sale price"
                                          rules="regex:\b\d+(?:\.\d\d?)?\b"
                                        >
                                          <b-form-input
                                            :id="'saleprice' + index"
                                            v-model="item.salePrice"
                                            :disabled="true"
                                            type="number"
                                            @input="showOffer(index)"
                                            :state="
                                              errors.length > 0 ? false : null
                                            "
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col>
                                    <!-- varint quantity -->
                                    <b-col md="4">
                                      <b-form-group
                                        label="Quantity"
                                        label-for="quantity"
                                      >
                                        <validation-provider
                                          #default="{ errors }"
                                          name="quantity"
                                          rules="required|regex:^[0]*[1-9][0-9]*$"
                                        >
                                          <b-form-input
                                            :id="'quantity' + index"
                                            min="1"
                                            type="number"
                                            v-model="item.vquantity"
                                            :state="
                                              errors.length > 0 ? false : null
                                            "
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col>
                                    <!-- varint Condition -->
                                    <b-col md="4">
                                      <validation-provider
                                        #default="{ errors }"
                                        name="condition"
                                        rules="required"
                                      >
                                        <b-form-group
                                          label="Condition"
                                          label-for="Condition"
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                        >
                                          <v-select
                                            id="condition"
                                            v-model="vConditionSelect[index]"
                                            :state="
                                              vConditionSelect[index] === null
                                                ? false
                                                : true
                                            "
                                            :dir="
                                              $store.state.appConfig.isRTL
                                                ? 'rtl'
                                                : 'ltr'
                                            "
                                            :options="conditionOptions"
                                            :selectable="
                                              (option) =>
                                                !option.value.includes(
                                                  'select_value'
                                                )
                                            "
                                            label="text"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                    <b-col v-if="item.offerHide" cols="8">
                                      <b-row>
                                        <b-col>
                                          <app-collapse
                                            id="faq-payment-qna"
                                            accordion
                                            type="margin"
                                            class="mt-0 mb-0"
                                          >
                                            <app-collapse-item
                                              :key="index"
                                              title="Click To Customize Offer"
                                            >
                                              <b-row>
                                                <b-col md="6">
                                                  <b-form-group
                                                    label="User Numbers"
                                                    label-for="User-Numbers"
                                                  >
                                                    <validation-provider
                                                      #default="{ errors }"
                                                      name="user numbers"
                                                      rules="required|regex:^[0]*[1-9][0-9]*$"
                                                    >
                                                      <b-form-input
                                                        min="1"
                                                        id="User-Numbers"
                                                        type="number"
                                                        v-model="
                                                          item.offerBDel
                                                            .usernumbers
                                                        "
                                                        :state="
                                                          errors.length > 0
                                                            ? false
                                                            : null
                                                        "
                                                        placeholder="enter user numbers"
                                                      />
                                                      <small
                                                        class="text-danger"
                                                        >{{ errors[0] }}</small
                                                      >
                                                    </validation-provider>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col md="6">
                                                  <validation-provider
                                                    #default="{ errors }"
                                                    name="start date"
                                                    rules="required"
                                                  >
                                                    <b-form-group
                                                      label="Start Date"
                                                      label-for="start date"
                                                      :state="
                                                        errors.length > 0
                                                          ? false
                                                          : null
                                                      "
                                                    >
                                                      <flat-pickr
                                                        v-model="
                                                          item.offerBDel
                                                            .startdate
                                                        "
                                                        class="form-control"
                                                        :config="{
                                                          enableTime: true,
                                                          dateFormat:
                                                            'Y-m-d H:i',
                                                        }"
                                                      />
                                                      <b-form-invalid-feedback
                                                        :state="
                                                          errors.length > 0
                                                            ? false
                                                            : null
                                                        "
                                                      >
                                                        {{ errors[0] }}
                                                      </b-form-invalid-feedback>
                                                    </b-form-group>
                                                  </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                  <validation-provider
                                                    #default="{ errors }"
                                                    name="end date"
                                                    rules="required"
                                                  >
                                                    <b-form-group
                                                      label="End date"
                                                      label-for="end date"
                                                      :state="
                                                        errors.length > 0
                                                          ? false
                                                          : null
                                                      "
                                                    >
                                                      <flat-pickr
                                                        v-model="
                                                          item.offerBDel.enddate
                                                        "
                                                        class="form-control"
                                                        :config="{
                                                          enableTime: true,
                                                          dateFormat:
                                                            'Y-m-d H:i',
                                                        }"
                                                      />
                                                      <b-form-invalid-feedback
                                                        :state="
                                                          errors.length > 0
                                                            ? false
                                                            : null
                                                        "
                                                      >
                                                        {{ errors[0] }}
                                                      </b-form-invalid-feedback>
                                                    </b-form-group>
                                                  </validation-provider>
                                                </b-col>
                                              </b-row>
                                            </app-collapse-item>
                                          </app-collapse>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                    <b-col cols="12">
                                      <hr />
                                    </b-col>
                                    <b-col cols="12">
                                      <b-row>
                                        <b-col>
                                          <app-collapse
                                            id="faq-payment-qna"
                                            accordion
                                            type="margin"
                                            class="mt-0 mb-0"
                                          >
                                            <app-collapse-item
                                              :key="index"
                                              title="Click To Customize Languages"
                                            >
                                              <b-row>
                                                <b-col cols="12">
                                                  <div>
                                                    <!-- Row Loop -->
                                                    <b-row
                                                      v-for="(tran,
                                                      ind) in item.vtranslations"
                                                      :key="ind"
                                                    >
                                                      <!-- translation language -->
                                                      <b-col md="4">
                                                        <validation-provider
                                                          #default="{ errors }"
                                                          name="language"
                                                          rules="required"
                                                        >
                                                          <b-form-group
                                                            label="Language"
                                                            label-for="language"
                                                            :state="
                                                              errors.length > 0
                                                                ? false
                                                                : null
                                                            "
                                                          >
                                                            <v-select
                                                              id="language"
                                                              v-model="
                                                                tran.locale1
                                                              "
                                                              :disabled="
                                                                tran.locale1
                                                                  .disa
                                                              "
                                                              :state="
                                                                tran.locale1 ===
                                                                null
                                                                  ? false
                                                                  : true
                                                              "
                                                              :dir="
                                                                $store.state
                                                                  .appConfig
                                                                  .isRTL
                                                                  ? 'rtl'
                                                                  : 'ltr'
                                                              "
                                                              :options="
                                                                localeOptions
                                                              "
                                                              :selectable="
                                                                (option) =>
                                                                  !option.value.includes(
                                                                    'select_value'
                                                                  )
                                                              "
                                                              label="text"
                                                            />
                                                            <small
                                                              class="text-danger"
                                                              >{{
                                                                errors[0]
                                                              }}</small
                                                            >
                                                          </b-form-group>
                                                        </validation-provider>
                                                      </b-col>
                                                      <!-- translation short description -->
                                                      <b-col md="12">
                                                        <b-form-group
                                                          label="Short Description"
                                                          label-for="short-description"
                                                        >
                                                          <validation-provider
                                                            #default="{ errors }"
                                                            name="short-description"
                                                            rules="required"
                                                          >
                                                            <b-form-textarea
                                                              rows="3"
                                                              :id="
                                                                'vshort-description' +
                                                                index +
                                                                ind
                                                              "
                                                              v-model="
                                                                tran.short_description
                                                              "
                                                              :state="
                                                                errors.length >
                                                                0
                                                                  ? false
                                                                  : null
                                                              "
                                                            />
                                                            <small
                                                              class="text-danger"
                                                              >{{
                                                                errors[0]
                                                              }}</small
                                                            >
                                                          </validation-provider>
                                                        </b-form-group>
                                                      </b-col>
                                                      <!-- Remove Button -->
                                                      <b-col
                                                        md="4"
                                                        class="mb-50"
                                                      >
                                                        <b-button
                                                          v-ripple.400="
                                                            'rgba(234, 84, 85, 0.15)'
                                                          "
                                                          variant="outline-danger"
                                                          v-show="
                                                            !tran.locale1.disa
                                                          "
                                                          class="mt-0 mt-md-2"
                                                          @click="
                                                            vTransRemoveItem(
                                                              ind,
                                                              item
                                                            )
                                                          "
                                                        >
                                                          <feather-icon
                                                            icon="XIcon"
                                                            class="mr-25"
                                                          />
                                                          <span>Remove</span>
                                                        </b-button>
                                                      </b-col>
                                                      <!-- translation description -->
                                                      <b-col cols="12">
                                                        <b-form-group
                                                          label="Description"
                                                          label-for="description"
                                                        >
                                                          <validation-provider
                                                            #default="{ errors }"
                                                            name="description"
                                                            rules="required"
                                                          >
                                                            <vue-editor
                                                              id="vtranslationDescription"
                                                              v-model="
                                                                tran.description
                                                              "
                                                              :state="
                                                                errors.length >
                                                                0
                                                                  ? false
                                                                  : null
                                                              "
                                                            />
                                                            <small
                                                              class="text-danger"
                                                              >{{
                                                                errors[0]
                                                              }}</small
                                                            >
                                                          </validation-provider>
                                                        </b-form-group>
                                                      </b-col>
                                                      <b-col cols="12">
                                                        <hr />
                                                      </b-col>
                                                    </b-row>
                                                  </div>
                                                </b-col>
                                                <!-- add new button -->
                                                <b-col cols="12">
                                                  <b-button
                                                    v-ripple.400="
                                                      'rgba(255, 255, 255, 0.15)'
                                                    "
                                                    style="width: 40%;"
                                                    variant="outline-primary"
                                                    block
                                                    @click="
                                                      vTransRepeateAgain(item)
                                                    "
                                                  >
                                                    <feather-icon
                                                      icon="PlusIcon"
                                                      class="mr-25"
                                                    />
                                                    <span
                                                      >Add New Language</span
                                                    >
                                                  </b-button>
                                                </b-col>
                                              </b-row>
                                            </app-collapse-item>
                                          </app-collapse>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                    <b-col cols="12">
                                      <hr />
                                    </b-col>
                                    <b-col cols="12">
                                      <b-row>
                                        <b-col>
                                          <app-collapse
                                            id="faq-payment-qna"
                                            accordion
                                            type="margin"
                                            class="mt-0 mb-0"
                                          >
                                            <app-collapse-item
                                              :key="index"
                                              title="Click To Customize Images"
                                            >
                                              <b-row>
                                                <b-col cols="12">
                                                  <div>
                                                    <b-form
                                                      ref="vImagesform"
                                                      :style="{
                                                        height: trHeight,
                                                      }"
                                                      class="repeater-form"
                                                      @submit.prevent="
                                                        vImagesRepeateAgain(
                                                          item
                                                        )
                                                      "
                                                    >
                                                      <!-- Row Loop -->
                                                      <b-row
                                                        v-for="(im,
                                                        ind) in item.vimages2"
                                                        :id="im + ind"
                                                        :key="im + ind"
                                                        ref="row"
                                                      >
                                                        <!-- Image Reapeted -->
                                                        <b-col md="4">
                                                          <b-form-group
                                                            label="Image"
                                                            label-for="image"
                                                          >
                                                            <b-form-file
                                                              @change="
                                                                uploadImage3(
                                                                  $event,
                                                                  item,
                                                                  ind
                                                                )
                                                              "
                                                              :id="
                                                                'image' +
                                                                ind +
                                                                index +
                                                                ind
                                                              "
                                                              accept="images/*"
                                                            />
                                                          </b-form-group>
                                                        </b-col>
                                                        <!-- Remove Button -->
                                                        <b-col
                                                          lg="2"
                                                          md="3"
                                                          class="mb-50"
                                                        >
                                                          <b-button
                                                            v-ripple.400="
                                                              'rgba(234, 84, 85, 0.15)'
                                                            "
                                                            variant="outline-danger"
                                                            class="mt-0 mt-md-2"
                                                            @click="
                                                              vImagesRemoveItem(
                                                                ind,
                                                                item
                                                              )
                                                            "
                                                          >
                                                            <feather-icon
                                                              icon="XIcon"
                                                              class="mr-25"
                                                            />
                                                            <span>Remove</span>
                                                          </b-button>
                                                        </b-col>
                                                        <b-col cols="12">
                                                          <hr />
                                                        </b-col>
                                                      </b-row>
                                                    </b-form>
                                                  </div>
                                                </b-col>
                                                <!-- add new button -->
                                                <b-col cols="12">
                                                  <b-button
                                                    v-ripple.400="
                                                      'rgba(255, 255, 255, 0.15)'
                                                    "
                                                    style="width: 40%;"
                                                    variant="outline-primary"
                                                    block
                                                    @click="
                                                      vImagesRepeateAgain(item)
                                                    "
                                                  >
                                                    <feather-icon
                                                      icon="PlusIcon"
                                                      class="mr-25"
                                                    />
                                                    <span>Add New Image</span>
                                                  </b-button>
                                                </b-col>
                                              </b-row>
                                            </app-collapse-item>
                                          </app-collapse>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </app-collapse-item>
                              </b-col>
                              <!-- Remove Button -->
                              <b-col cols="2" class="mb-50">
                                <!-- <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="flat-danger" class=" m-2 border-0" @click="pricesremoveItem(index)">
                                                                            <feather-icon icon="XIcon" class="mr-25" />
                                                                            <span>Remove</span>
                                                                        </b-button> -->
                                <b-button
                                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                  variant="flat-success"
                                  class="m-2 border-0"
                                  @click="addVariant(index, index.toString())"
                                >
                                  <feather-icon
                                    icon="CheckIcon"
                                    class="mr-25"
                                  />
                                  <span>Add</span>
                                </b-button>
                              </b-col>
                              <b-col cols="12">
                                <hr />
                              </b-col>
                            </b-row>
                          </app-collapse>
                        </b-col>
                      </b-row>
                    </validation-observer>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>

import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
import { VueEditor } from 'vue2-editor'
export default {
  components: {
    VueEditor,
    flatPickr,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      errors_back: [],
      showDismissibleAlert: false,
      vendorCommissionRate: null,
      itemData: null,
      id: 0,
      attrSelect: [],
      attrOptions: [],
      attr2Options: [],
      vTransSelect: [],
      localeOptions: [
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      tranLocaleSelect: [
        {
          value: 'en',
          text: 'English',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
      ],
      vConditionSelect: [],
      conditionOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      valuesSelect: [],
      valuesOptions: [
        [
          {
            value: 'select_value',
            text: 'Select Value',
          },
        ],
      ],
      valuesID: [],
      combTextArr: [],
      combValuesArr: [],
      varints: [],
      product: {
        attributes: [],
        variants: [],
      },
      oldVariantTitle: [],
      oldVariantId: [],
    }
  },
  watch: {
    attrSelect: function () {
      this.getOptions()
    },
  },
  methods: {
    // set attrbuites
    getOptions() {
      this.attr2Options = this.attrOptions.filter(
        (item) => !this.attrSelect.includes(item)
      )
    },
    // generate variants
    generate() {
      for (let val in this.valuesSelect) {
        const vale = this.valuesSelect[val]
        this.product.attributes[val].values = []
        for (let valID in vale) {
          this.product.attributes[val].values.push(vale[valID].value)
        }
      }
      for (let att in this.product.attributes) {
        const attrbu = this.product.attributes[att]
        attrbu.id = this.attrSelect[att].value
      }
      for (let val in this.valuesSelect) {
        const vale = this.valuesSelect[val]
        this.combTextArr[val].length = 0
        for (let valID in vale) {
          this.combTextArr[val].push(vale[valID].text)
        }
      }
      for (let val in this.valuesSelect) {
        const vale = this.valuesSelect[val]
        this.combValuesArr[val].length = 0
        for (let valID in vale) {
          this.combValuesArr[val].push(vale[valID].value)
        }
      }
      this.getCombn(this.combTextArr)
      this.getCombn2(this.combValuesArr)
      for (let trans in this.itemData.translation) {
        for (let inde in this.varints) {
          if (this.itemData.translation[trans].locale == 'en') {
            this.varints[inde].vtranslations.push({
              locale1: {
                text: 'English',
                value: 'en',
                disa: true,
              },
              locale: '',
              description: this.itemData.translation[trans].description,
              short_description: this.itemData.translation[trans]
                .short_description,
            })
          } else {
            this.varints[inde].vtranslations.push({
              locale1: {
                text: 'Arabic',
                value: 'ar',
                disa: true,
              },
              locale: '',
              description: this.itemData.translation[trans].description,
              short_description: this.itemData.translation[trans]
                .short_description,
            })
          }
        }
      }
      for (let tran in this.varints) {
        if (
          this.itemData.original_price != '' &&
          this.itemData.original_price != null
        ) {
          this.varints[tran].voriginal_price = this.itemData.original_price
          let price = parseFloat(this.varints[tran].voriginal_price)
          let profit = (price * this.vendorCommissionRate) / 100
          this.varints[tran].salePrice = price + profit
          this.vConditionSelect[tran] = this.conditionSelect
          this.varints[tran].amountPriceDis = false
        } else {
          this.varints[tran].voriginal_price = this.itemData.original_price
          // this.vConditionSelect[tran] = this.conditionSelect
          this.varints[tran].amountPriceDis = true
        }
        for (let vTran in this.varints[tran].vtranslations) {
          this.varints[tran].vtranslations[
            vTran
          ].description = this.itemData.translation[vTran].description
          this.varints[tran].vtranslations[
            vTran
          ].short_description = this.itemData.translation[
            vTran
          ].short_description
        }
      }
      // this.subActive = false
    },
    // make combination to get names of variants
    getCombn(arr) {
      if (arr.length == 1) {
        var ans = []
        for (let ind in arr[0]) {
          ans.push(arr[0][ind])
        }
        this.varints.length = 0
        this.vConditionSelect.length = 0
        for (let inde in ans) {
          if (!this.oldVariantTitle.includes(ans[inde])) {
            this.vConditionSelect.push()
            this.varints.push({
              vname: ans[inde],
              vquantity: '',
              vcondition_id: '',
              voriginal_price: '',
              salePrice: '',
              values: [],
              amountPriceDis: true,
              vtranslations: [],
              offerHide: false,
              offerBDel: {
                type: 'fixed_price',
                amount: '',
                startdate: '',
                enddate: '',
                usernumbers: '',
              },
              offer: {
                type: 'fixed_price',
                amount: '',
                startdate: '',
                enddate: '',
                usernumbers: '',
              },
              vimages: [
                {
                  vname: [],
                },
              ],
              vimages2: [
                {
                  vname: [],
                },
              ],
            })
            this.vConditionSelect.push()
          }
        }
        return ans
      } else {
        var ans = []
        // recur with the rest of the array.
        var otherCases = this.getCombn(arr.slice(1))
        for (var i = 0; i < otherCases.length; i++) {
          for (var j = 0; j < arr[0].length; j++) {
            ans.push(arr[0][j] + '-' + otherCases[i])
          }
        }
        this.varints.length = 0
        this.vConditionSelect.length = 0
        for (let ind in ans) {
          if (!this.oldVariantTitle.includes(ans[ind])) {
            this.vConditionSelect.push()
            this.varints.push({
              vname: ans[ind],
              vquantity: '',
              vcondition_id: '',
              voriginal_price: this.itemData.original_price,
              salePrice: this.itemData.sale_price,
              values: [],
              amountPriceDis: true,
              vtranslations: [],
              offerHide: false,
              offerBDel: {
                type: 'fixed_price',
                amount: '',
                startdate: '',
                enddate: '',
                usernumbers: '',
              },
              offer: {
                type: 'fixed_price',
                amount: '',
                startdate: '',
                enddate: '',
                usernumbers: '',
              },
              vimages: [
                {
                  vname: [],
                },
              ],
              vimages2: [
                {
                  vname: [],
                },
              ],
            })
            this.vConditionSelect.push()
          }
        }
        return ans
      }
    },
    // make combinations to get values of variants
    getCombn2(arr) {
      if (arr.length == 1) {
        var ans = []
        for (let ind in arr[0]) {
          ans.push(arr[0][ind])
        }
        for (let inde in ans) {
          if (!this.oldVariantId.includes(ans[inde])) {
            this.varints[inde].values = ans[inde]
          }
        }
        return ans
      } else {
        var ans = []
        // recur with the rest of the array.
        var otherCases = this.getCombn2(arr.slice(1))
        for (var i = 0; i < otherCases.length; i++) {
          for (var j = 0; j < arr[0].length; j++) {
            ans.push(arr[0][j] + '-' + otherCases[i])
          }
        }
        const arr2 = []
        for (let ind in ans) {
          if (!this.oldVariantId.includes(ans[ind])) {
            arr2.push(ans[ind])
            // this.varints[ind].values = [ans[ind]]
          }
        }
        for (let index in this.varints) {
          this.varints[index].values = arr2[index]
        }
        return ans
      }
    },
    // get values
    getValues(index, id) {
      axios
        .get('products/get_values/' + id)
        .then((result) => {
          this.valuesOptions[index].length = 0
          this.valuesSelect[index] = null
          let data = result.data.data
          for (let ind in data) {
            if (data[ind].translation != null) {
              this.valuesOptions[index].push({
                value: data[ind].id.toString(),
                text: data[ind].translation.name,
              })
            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // show offer in variants
    showOffer(index) {
      const oP = parseFloat(this.varints[index].voriginal_price)
      const sP = parseFloat(this.varints[index].salePrice)
      if (
        this.varints[index].salePrice > 0 &&
        this.varints[index].salePrice != '' &&
        this.varints[index].salePrice != null &&
        oP >= sP
      ) {
        this.varints[index].offerHide = true
        this.varints[index].offerBDel.amount =
          this.varints[index].voriginal_price - this.varints[index].salePrice
        this.varints[index].offerBDel.type = 'fixed_price'
      } else {
        this.varints[index].offerHide = false
        ;(this.varints[index].offerBDel.amount = ''),
          (this.varints[index].offerBDel.type = ''),
          (this.varints[index].offerBDel.startdate = '')
        this.varints[index].offerBDel.enddate = ''
        this.varints[index].offerBDel.usernumbers = ''
      }
    },
    // return vendor commission rate
    fetchRateData() {
      axios
        .get('vendors/' + this.vendorSelect.value + '/show')
        .then((result) => {
          const data = result.data.data
          this.vendorCommissionRate = data.commission.rate
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // when change amount of offer
    changeAmount(index) {
      const oP = parseFloat(this.varints[index].voriginal_price)
      const aP = parseFloat(this.varints[index].offerBDel.amount)
      if (
        this.varints[index].offerBDel.amount > 0 &&
        this.varints[index].offerBDel.amount != '' &&
        this.varints[index].offerBDel.amount != null &&
        oP > aP
      ) {
        this.varints[index].offerHide = true
        let price =
          parseFloat(this.varints[index].voriginal_price) -
          parseFloat(this.varints[index].offerBDel.amount)
        let profit = (price * this.vendorCommissionRate) / 100
        this.varints[index].salePrice = price + profit
        this.varints[index].offerBDel.type = 'fixed_price'
      } else {
        ;(this.varints[index].offerBDel.amount = ''),
          (this.varints[index].offerBDel.type = '')
        this.varints[index].offerBDel.startdate = ''
        this.varints[index].offerBDel.enddate = ''
        this.varints[index].offerBDel.usernumbers = ''
        this.varints[index].offerHide = false
        let price = parseFloat(this.varints[index].voriginal_price)
        let profit = (price * this.vendorCommissionRate) / 100
        this.varints[index].salePrice = price + profit
      }
    },
    // when change original price of vaiants
    changeOP(index) {
      if (
        this.varints[index].voriginal_price > 0 &&
        this.varints[index].voriginal_price != '' &&
        this.varints[index].voriginal_price != null
      ) {
        this.varints[index].amountPriceDis = false
        let price = parseFloat(this.varints[index].voriginal_price)
        let profit = (price * this.vendorCommissionRate) / 100
        this.varints[index].salePrice = price + profit
        this.varints[index].offerBDel.type = 'fixed_price'
      } else {
        this.varints[index].amountPriceDis = true
        ;(this.varints[index].amount = ''),
          (this.varints[index].offerBDel.amount = ''),
          (this.varints[index].offerBDel.type = ''),
          (this.varints[index].offerBDel.startdate = '')
        this.varints[index].offerBDel.enddate = ''
        this.varints[index].offerBDel.usernumbers = ''
        this.varints[index].salePrice = ''
        this.varints[index].amountPriceDis = false
        let price = parseFloat(this.varints[index].voriginal_price)
        let profit = (price * this.vendorCommissionRate) / 100
        this.varints[index].salePrice = price + profit
      }
    },
    taxonomyrepeateAgain() {
      //    this.refheightForm = this.$refs.Taxonomiesform
      this.product.attributes.push({
        id: '',
        values: [],
      })
      this.combTextArr.push([])
      this.attrSelect.push({})
      this.valuesOptions.push([
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ])
      this.valuesSelect.push()
      this.valuesID.push()
    },
    taxonomyremoveItem(index) {
      if (this.product.attributes.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.valuesOptions[index].length = 0
        this.combTextArr.splice(index, 1)
        this.product.attributes.splice(index, 1)
        this.attrSelect.splice(index, 1)
        this.valuesSelect.splice(index, 1)
        this.valuesID.splice(index, 1)
      }
    },
    pricesremoveItem(index) {
      if (this.varints.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.varints.splice(index, 1)
        this.vConditionSelect.splice(index, 1)
      }
    },
    addVariant(index, indString) {
      this.$refs.element[index].validate().then((success) => {
        if (success) {
          for (let ind in this.varints[index].vtranslations) {
            this.varints[index].vtranslations[ind].locale = this.varints[
              index
            ].vtranslations[ind].locale1.value
          }
          const vria = {
            original_price: this.varints[index].voriginal_price,
            quantity: this.varints[index].vquantity,
            condition_id: this.vConditionSelect[index].value,
            product_id: this.id,
            images: [],
            values: this.varints[index].values
              .split('-')
              .map((item) => item.trim()),
            translations: this.varints[index].vtranslations,
            offer: {
              type: this.varints[index].offerBDel.type,
              startdate: this.varints[index].offerBDel.startdate,
              enddate: this.varints[index].offerBDel.enddate,
              usernumbers: this.varints[index].offerBDel.usernumbers,
              amount: this.varints[index].offerBDel.amount,
            },
          }
          if (vria.offer.amount == '') {
            delete vria.offer
          }
          for (let inde in this.varints[index].vimages2) {
            vria.images.push({
              name: this.varints[index].vimages2[inde].vname,
            })
            if (vria.images[inde].name.includes(',')) {
              vria.images[inde].name = vria.images[inde].name
                .split(',')
                .map((item) => item.trim())
              vria.images[inde].name = vria.images[inde].name[1]
            }
          }
          axios
            .post('products/' + this.id + '/variant-store', vria)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    vTransRepeateAgain(item) {
      item.vtranslations.push({
        locale1: {
          text: '',
          value: '',
        },
        locale: '',
        description: '',
        short_description: '',
      })
    },
    vTransRemoveItem(index, item) {
      if (item.vtranslations.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        item.vtranslations.splice(index, 1)
      }
    },
    vImagesRepeateAgain(item) {
      //  this.refheightForm = this.$refs.pricesForm
      item.vimages.push({
        vname: [],
      })
      item.vimages2.push({
        vname: [],
      })
    },
    vImagesRemoveItem(index, item) {
      if (item.vimages2.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        item.vimages2.splice(index, 1)
        item.vimages.splice(index, 1)
      }
    },
    // upload function vimages of item
    uploadImage3(event, item, index) {
      let files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        item.vimages2[index].vname = []
        return
      } else {
        this.createImage3(files[0], index, item)
      }
    },
    createImage3(file, ind, item) {
      let reader = new FileReader()
      reader.onload = (e) => {
        item.vimages2[ind].vname = e.target.result
      }
      reader.readAsDataURL(file)
    },
    fetchData() {
      axios
        .get('products/' + this.id)
        .then((result) => {
          this.itemData = result.data.data
          this.vendorCommissionRate = this.itemData.vendor.commission.rate
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    getVariantTitles() {
      axios
        .get('products/' + this.id + '/show/variantsname')
        .then((result) => {
          const data = result.data.data
          for (let index in data.variantsName) {
            this.oldVariantTitle.push(data.variantsName[index])
          }
          for (let index in data.variantsId) {
            this.oldVariantId.push(data.variantsId[index])
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    getAttrValue() {
      axios
        .get('products/' + this.id + '/show/sorted-attributes')
        .then((result) => {
          const data = result.data.data
          if (data.length > 0) {
            for (let attr in data) {
              if (data[attr].translation != null) {
                this.attrOptions.push({
                  value: data[attr].id.toString(),
                  text: data[attr].translation.name,
                })
                this.attrSelect.push({
                  value: data[attr].id.toString(),
                  text: data[attr].translation.name,
                })
                this.product.attributes.push({
                  id: '',
                  values: [],
                })
                this.valuesOptions.push([])
                for (let val in data[attr].values) {
                  this.valuesOptions[attr].push({
                    text: data[attr].values[val].translation.name,
                    value: data[attr].values[val].id.toString(),
                  })
                }
              }
              this.combTextArr.push([])
              this.combValuesArr.push([])
            }
            this.attr2Options = this.attrOptions
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
  created() {
    this.id = this.$route.params.id
    this.fetchData()
    this.getVariantTitles()
    this.getAttrValue()
    axios
      .get('products/create')
      .then((result) => {
        const data = result.data.data
        if (data.conditions.length > 0) {
          for (let ind in data.conditions) {
            this.conditionOptions.push({
              text: data.conditions[ind].name.en,
              value: data.conditions[ind].id.toString(),
            })
          }
        }
        // if (data.attributes.length > 0) {
        //     for (let attr in data.attributes) {
        //         if (data.attributes[attr].translation != null) {
        //             this.attrOptions.push({
        //                 value: data.attributes[attr].id.toString(),
        //                 text: data.attributes[attr].translation.name
        //             })
        //         }
        //     }
        //     this.attr2Options = this.attrOptions
        // }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
}
</script>
<style scoped lang="scss">
.repeater-form {
  transition: 0.35s height;
}
</style>
